import React from "react"
import Layout from "../components/Layout"
import Footer from "../components/Footer"
import { FaChevronLeft } from "react-icons/fa"
import { Link } from "gatsby"
export default () => (
  <Layout>
    <main role="main">
      <div
        class="section section-md section-image overlay-dark text-white overflow-hidden"
        style={{ backgroundImage: `url("../hero-2.jpg")` }}
      ></div>
      <div class="section section-md">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h1 class="mb-5">Privacy Policy</h1>
              <p>
                <em>Last Updated: 13 February 2020</em>
              </p>
              <p>
                As part of our business operations, we may collect your personal
                information. This privacy policy explains how we collect
                personal information and how we maintain, use and disclose that
                information. It also provides some detail about your privacy
                rights along with our general rights and obligations and our
                policy in relation to the personal information we keep on
                record. This privacy policy does not apply to parties that may
                be linked or associated with our website and to whom you may
                disclose personal information. Those parties are responsible for
                the privacy of the information they collect and should be
                contacted directly for details of their privacy policies. We are
                bound by the Australian Privacy Principles (APPs), which are
                contained in the Privacy Act 1988 (Privacy Act). If you have any
                questions regarding this privacy policy, please contact us using
                the contact details below.
              </p>
              <h2>Your privacy is important to us</h2>
              <p>
                We recognise that your privacy is very important and that you
                have a right to control your personal information. Personal
                information is information or an opinion about an individual
                whose identity is apparent, or can reasonably be ascertained. It
                includes all personal information, regardless of its source. We
                know that providing personal information is an act of trust and
                we take that seriously. The following sections set out how we
                handle and protect your personal information and safeguard your
                privacy.
              </p>
              <h2>What information do we collect?</h2>
              <p>
                The types of information we keep on record will depend on the
                type of product or service requested by you. Generally, we keep
                a record of the following types of information about you:
              </p>
              <ul>
                <li>
                  information that identifies you, such as your name and address
                  and other information provided by you;
                </li>
                <li>
                  if you use a comparison tool, calculator, contact form, online
                  survey or other tool (&ldquo;Website Tool&rdquo;) on our
                  website, the information that you enter into the Website Tool.
                  This could include information about your name and contact
                  details, employment status and income, financial situation,
                  date of birth, gender and drivers licence number;
                </li>
                <li>
                  your IP address or unique device number for your interaction
                  with various parts of our website. Your IP address and/or
                  device number is an identifier for your computer or mobile
                  device when you are using the internet;
                </li>
                <li>
                  your credit score, where you authorise us to obtain it from a
                  credit reporting body.
                </li>
              </ul>
              <p>
                We do not seek to collect sensitive information (for example
                health information or information about your racial or ethnic
                origin or any criminal record) unless it is necessary for our
                business purposes. If we do have to collect sensitive
                information, we will do so in accordance with the APPs. During
                our dealings with you, we may gather information about you
                relating to all products and services that we or our business
                partners provide to you. This information is used to help us
                decide how we can improve our service delivery to you. We
                collect and hold information about you when you:
              </p>
              <ul>
                <li>complete an online form or use a Website Tool;</li>
                <li>
                  provide us with your email address and/or any other
                  identifying information you provide such as a name or phone
                  number; or
                </li>
                <li>
                  access and use our services through our website or via our
                  advertising on another site.
                </li>
              </ul>
              <p>
                We also collect some statistical information about visitors to
                our website (for example, the number of visitors, pages viewed,
                types of transactions conducted, time online and documents
                downloaded). Some of this statistical information is collected
                by using cookies, but none of the statistical information we
                collect allows us to identify a visitor. We use this information
                to evaluate our website performance and continually improve our
                services. We may disclose this information in an aggregated form
                to third parties including advertisers or potential advertisers.
                We allow third-party companies to collect certain anonymous
                information when you visit our website. These companies may use
                non-personally identifiable information during your visits to
                this and other websites in order to provide advertisements about
                goods and services likely to be of greater interest to you.
                These companies typically use a cookie or a third party web
                beacon to collect this information. To learn more about this
                behavioural advertising practice, you can visit{" "}
                <a href="https://www.iabaustralia.com.au/">IAB Australia</a>.
                Other than statistical information, we do not collect any
                information about you through our website unless you provide the
                information to us. You need not give us any of the personal
                information about you or any other person which may be requested
                in our communications with you. However, without that
                information, we may not be able to fulfil your request or
                provide you with an appropriate level of service.
              </p>
              <h2>How do we use your personal information?</h2>
              <p>We use your personal information to:</p>
              <ul>
                <li>
                  display content and advertising that are better filtered to
                  your interests, preferences and experiences;
                </li>
                <li>
                  provide you with our services (including comparing or
                  providing you information about products or services);
                </li>
                <li>respond to your requests;</li>
                <li>
                  communicate with you, including by email, mail or telephone;
                </li>
                <li>
                  review your dealings with us, including the products and
                  services you use, your potential needs, and new products that
                  might be of interest to you or other customers;
                </li>
                <li>
                  investigate any complaints about or made by you, or if we have
                  reason to suspect that you are in breach of any of our Terms
                  of Use or that you are or have been otherwise engaged in any
                  unlawful activity;
                </li>
                <li>
                  operate and improve our websites, products and services;
                </li>
                <li>
                  provide you with news and information about our products and
                  services (including newsletters, product surveys and special
                  offers);
                </li>
                <li>provide you with more effective customer service;</li>
                <li>enable us to conduct customer research;</li>
                <li>
                  perform research and analysis aimed at improving our products,
                  services and technologies;
                </li>
                <li>
                  occasionally send marketing and promotional material about our
                  products and/or services (or the products and/or services of
                  our related companies) that we believe you may be interested
                  in.
                </li>
                <li>
                  conduct competitions, promotions or scholarships on behalf of
                  Topbrokers and selected third parties;
                </li>
                <li>
                  ensure that our internal business operations are running
                  smoothly which may include fulfilling legal requirements and
                  conducting confidential systems maintenance and testing;
                  and/or
                </li>
                <li>
                  using personal information as otherwise required or permitted
                  by any law (including the Privacy Act).
                </li>
              </ul>
              <h2>Who do we disclose personal information to?</h2>
              <p>We may disclose your personal information to:</p>
              <ul>
                <li>
                  any organisation which you request us to, including a
                  financial adviser, broker, solicitor, finance broker or
                  accountant;
                </li>
                <li>
                  credit providers (such as by pre-populating the lender&rsquo;s
                  application form with the information you give us);
                </li>
                <li>
                  our external service providers (some of which may be located
                  overseas) for the purposes of our business, for example IT
                  service providers. Information is only disclosed in accordance
                  with Australian Privacy Principles. We take reasonable steps
                  to ensure that overseas service providers whose servers may be
                  used to store data comply with APPs;
                </li>
                <li>
                  product and services providers for operational purposes, and
                  also because from time to time they may choose to provide you
                  with information about their products and services. You may
                  opt-out of receipt of such marketing at any time by contacting
                  directly those organisations which have obtained your
                  information. If you do not have their details, you can request
                  them from us on the details below;
                </li>
                <li>
                  our related companies and other organisations where reasonable
                  to do so in relation to the operation of our business;
                </li>
                <li>
                  if we consider it necessary to do so in order to enforce or
                  apply the terms of any of our agreements with you;
                </li>
                <li>
                  contractors providing licensed collections services, credit
                  card payment processing and CRM services, who comply with our
                  privacy and security policies;
                </li>
                <li>if we sell our business or part of it to a third party;</li>
                <li>
                  in extreme circumstances to protect the personal safety of our
                  customers, website users, staff or the public; and
                </li>
                <li>
                  law enforcement, regulatory and government bodies, government
                  agencies and courts, if we are required to by law to do so.
                </li>
              </ul>
              <p>
                We take all reasonable steps to protect your personal
                information from misuse, loss, unauthorised access, modification
                or disclosure.
              </p>
              <h2>How do we store personal information?</h2>
              <p>
                We provide a robust level of security when you use our online
                products and services. We have physical, electronic and
                procedural safeguards to protect your information which is held
                by us. Access to information stored electronically is restricted
                to staff whose job purpose requires access. We use secure
                methods to destroy or de-identify any personal information as
                soon as the law permits, provided the information is no longer
                needed by us for any purpose.
              </p>
              <p>
                If there is a suspected or an actual data breach which may
                compromise personal information, Topbrokers will promptly
                undertake an assessment of the incident. Where relevant,
                immediate steps will be taken to contain the breach. These steps
                may include limiting any further access or distribution of the
                affected personal information, or the possible compromise of
                other personal information.
              </p>
              <p>
                If the unauthorised access, disclosure or loss of personal
                information is likely to cause serious harm to one or more
                individuals and the likely risk of serious harm has not been
                prevented by remedial action, Topbrokers will notify the
                affected individuals and the Office of the Australian
                Information Commissioner as soon as practicable. The
                notification will include Topbrokers' identity and contact
                details, a description of the incident, the kind/s of
                information concerned and any recommended steps for affected
                individuals.
              </p>
              <p>
                Following any data breach incident, Topbrokers will undertake a
                review process to help prevent future breaches.
              </p>
              <h2>How do we contact you?</h2>
              <p>
                Our primary means of contacting you is electronic, such as via
                email, phone and SMS. As a business with services that are
                primarily provided online, we have inferred your consent to
                communicate electronically. You can withdraw this consent by
                using the unsubscribe facility in these messages or by providing
                us with written notice. Where you have provided a phone number,
                we may also contact you by phone.
              </p>
              <h2>Our use of cookies</h2>
              <p>
                A &ldquo;cookie&rdquo; is a small text file that is placed on
                your computer by a Web server. Cookies contain information that
                can later be read by a Web server in the domain that issued the
                cookie to you.
              </p>
              <h2>How we use cookies</h2>
              <p>
                We use cookies to enable you to sign in to our services and to
                help personalise your online experience. One of the primary
                purposes of cookies is to store your preferences and other
                information in order to save you time by eliminating the need to
                repeatedly enter the same information and to display your
                personalised content.
              </p>
              <h2>Declining cookies</h2>
              <p>
                You have the ability to accept or decline cookies. Most Web
                browsers automatically accept cookies, but you can usually
                modify your browser setting to decline cookies if you prefer. If
                you choose to decline cookies, you may not be able to use other
                interactive features of our website and services that depend on
                cookies, and some advertising preferences that are dependent on
                cookies may not be able to be respected. If you choose to accept
                cookies, you also have the ability to later delete cookies that
                you have accepted. If you chose to delete cookies, any settings
                and preferences, including advertising preferences, controlled
                by those cookies will be deleted and may need to be recreated.
              </p>
              <h2>Use of web beacons</h2>
              <p>
                Our websites or partners' websites may contain electronic images
                known as &ldquo;web beacons&rdquo; &ndash; sometimes called
                single-pixel gifs &ndash; that may be used to assist in
                delivering cookies on our sites and allow us to count users who
                have visited those pages and to deliver other services.
              </p>
              <h2>How we use web beacons</h2>
              <p>
                We may include web beacons on all webpages on Topbrokers or our
                partners in order to determine whether messages have been opened
                and acted upon. We may also employ web beacons from third
                parties in order to help us compile aggregated statistics
                regarding the effectiveness of our promotional campaigns or
                other operations of our websites.
              </p>
              <p>
                Finally, we may work with other companies that advertise on our
                website to place their web beacons on our sites in order to
                allow us to gather statistics and better target advertisements
                on our website that may result in a purchase or other action on
                the advertiser&rsquo;s site.
              </p>
              <h2>Amendment of this policy</h2>
              <p>
                We may amend this policy from time to time. Not all changes to
                our privacy policy will require your consent, for example where
                office security procedures are changed. We will notify you of
                any change to our information handling policy that requires your
                consent before being implemented. If you do not wish to receive
                information about our products and services, then please tell
                us. You can do this at any time by writing to us or telephoning
                us at the contact details listed below.
              </p>
              <h2>Access to your information</h2>
              <p>
                You can request access at any time to personal information we
                hold about you by using the contact details below. We will
                process your request within a reasonable time, usually 14 days
                for a straightforward request. More time may be needed,
                depending on the nature of the request. There is no fee for
                requesting access to your information, however, we may charge
                you the reasonable cost of processing your request. Sometimes we
                are not required to provide you with access &ndash; for example
                if the law says we can deny access. If we refuse you access to
                your personal information, we will tell you the reason why.
              </p>
              <h2>Correction of information</h2>
              <p>
                We try to ensure that all information we hold about you which we
                collect, use or disclose is accurate, complete and up to date.
                You may ask us at any time to correct personal information held
                by us about you, which you believe is incorrect or out of date.
                We will deal with your request within a reasonable time. In this
                policy, the reference to &ldquo;we/us&rdquo; means Topbrokers.
              </p>
              <h2>Contact details and complaints</h2>
              <p>
                If you have any questions, concerns or complaints about whether
                we have complied with the Privacy Act, the Australian Privacy
                Principles, or this Privacy Policy, please contact us on: ****
                *** ***, 9am &ndash; 5pm on business days in Sydney, NSW. Or
                email support@topbrokers.com.au. We will try to answer any
                questions you may have, correct any error on our part or resolve
                any complaint that you may have about our information handling
                practices. If we do not resolve a complaint to your
                satisfaction, you also have the right to complain to the Office
                of the Australian Information Commissioner (1300 363 992 or
                enquiries@oaic.gov.au). You acknowledge and accept that your use
                of the Topbrokers website indicates your acceptance of the
                Topbrokers website and Privacy Policy.
              </p>
              <div class="mt-6 d-flex align-items-center flex-column">
                <div>
                  <Link to="/" className="btn btn-sm btn-primary animate-hover">
                    <i class="fas fa-chevron-left mr-2 pl-2 animate-left-4">
                      <FaChevronLeft></FaChevronLeft>
                    </i>
                    Back to homepage
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </Layout>
)
